var copy=  {
    exec: (id) => {
        var element = document.getElementById(id);
        if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
        try {
            document.execCommand('copy');
            return true;
        } catch (err) {
            console.error('unable to copy text');
            return false;
        }
    }
}
